import axios from 'axios';
import { getServiceUrl } from '../../utilities';

const SERVICE_URL = getServiceUrl() + '/r4r';

export type AssignmentData = {
  results: ClassData[];
};

export type ClassData = {
  classId: number;
  className?: string;
  students: StudentData[];
};

export type StudentData = {
  studentId: number;
  firstName?: string;
  lastName?: string;
  assigned: true;
};

function getAssignmentsByText(staffId: string, orgId: string, textId: string, schoolYear: string) {
  return axios
    .get<AssignmentData>(
      `${SERVICE_URL}/assignments/staff/${staffId}/organization/${orgId}/text/${textId}?schoolYear=${schoolYear}`
    )
    .then((response) => response.data);
}

async function setAssignmentOfText(staffId: string, textId: string, data) {
  // set assignment text
  const putCall = axios.put(`${SERVICE_URL}/assignments/staff/${staffId}/text/${textId}`, data, {
    headers: {
      'Content-Type': 'application/json',
      Accept: '*/*'
    }
  });
  if (putCall) {
    return putCall.then((response) => {
      return response.data;
    });
  }
}

export { getAssignmentsByText, setAssignmentOfText };
