import axios from 'axios';
import { ModuleId } from '../../types';
import { stringifyArrayParams, getServiceUrl } from '../../utilities';

const SERVICE_URL = getServiceUrl() + '/r4r';

export enum AssetType {
  pdf = 'PDF',
  video = 'VIDEO',
  image = 'IMAGE',
  lesson = 'LESSON',
  ebook = 'EBOOK',
  activity = 'ACTIVITY',
  other = 'NA',
  audio = 'AUDIO'
}

export type LessonTabData = {
  title: string;
  sequence: number;
  content: JSON[];
};

export type LessonData = {
  title: string;
  moduleUuid: string;
  moduleName: string;
  tabs: string[];
};

export type AssetByModule = {
  uuid: ModuleId;
  name: string;
  assets: AssetData[];
};

export type AssetByModuleMap = {
  row: number;
  assets: AssetByModule[];
};

export type Asset = {
  uuid: string;
  assetTypeUuid: string;
  filename: string;
  title: string;
  description: string;
  creditText: string;
  isbn: string;
  limelightId: number;
  metadata: string;
};

export type AssetData = {
  uuid: string;
  title: string;
};

export type ActivityAssetData = AssetData & {
  assetType: AssetType.activity;
};

export type LimelightAssetData = AssetData & {
  limelightId: string;
};

export type VideoAssetData = LimelightAssetData & {
  assetType: AssetType.video;
};

export type AudioAssetData = LimelightAssetData & {
  assetType: AssetType.audio;
};

export type PdfAssetData = AssetData & {
  assetType: AssetType.pdf;
  filename: string;
};

export type EbookAssetData = AssetData & {
  assetType: AssetType.ebook;
  filename: string;
  isbn: string;
};

export type LessonAssetData = {
  moduleUuid: string;
  lessons: Array<AssetData & { assetType: AssetType.lesson }>;
};

export type RelatedMaterialData = {
  ACTIVITY?: ActivityAssetData[];
  PDF?: PdfAssetData[];
  VIDEO?: VideoAssetData[];
  AUDIO?: AudioAssetData[];
  EBOOK?: EbookAssetData[];
  LESSON?: LessonAssetData[];
};

export type AssetGroupedByCategory = {
  skillCategoryUuid: string;
  skillCategoryName: string;
  assets: Array<AssetData>;
};

function getLesson(lessonUuid: string) {
  return axios.get<LessonData>(`${SERVICE_URL}/lesson/${lessonUuid}`).then((response) => response.data);
}

function getLessonTab(lessonUuid: string, tabNumber: number) {
  return axios
    .get<LessonTabData>(`${SERVICE_URL}/lesson/${lessonUuid}/tab/${tabNumber}`)
    .then((response) => response.data);
}

function getRelatedMaterials(lessonUuid: string, moduleUuids: ModuleId[]) {
  const params = stringifyArrayParams({ moduleUuids });
  return axios
    .get<RelatedMaterialData>(`${SERVICE_URL}/lesson/${lessonUuid}/related?${params}`)
    .then((response) => response.data);
}

function getLessonAssetsBySkill(skillUuid: string, moduleUuids: ModuleId[]) {
  const params = stringifyArrayParams({ moduleUuids });
  const uri = `${SERVICE_URL}/lesson/skill/${skillUuid}?${params}`;
  return axios.get<AssetByModule[]>(uri).then((response) => response.data);
}

function getLessonAssetsBySkillCategory(skillCategoryUuid: string, moduleUuids: ModuleId[]) {
  const params = stringifyArrayParams({ moduleUuids });
  const uri = `${SERVICE_URL}/lesson/skillCategory/${skillCategoryUuid}?${params}`;
  return axios.get<AssetByModule[]>(uri).then((response) => response.data);
}

export {
  getLessonTab,
  getLesson,
  getRelatedMaterials,
  getLessonAssetsBySkill,
  getLessonAssetsBySkillCategory
};
