import logoSrd from '../assets/images/logo_srd_black.svg'
import logoRtk from '../assets/images/logo_rtk_black.svg'
import logoWbtp from '../assets/images/logo_wbtp_black.svg'
import backgroundSrd from '../assets/images/background_srd.svg'
import backgroundRtk from '../assets/images/background_rtk.svg'
import backgroundWbtp from '../assets/images/background_wbtp.svg'
import coachPhonics from '../assets/images/coach_phonics.svg'
import coachSrd from '../assets/images/coach_srd.svg'
import coachRtk from '../assets/images/coach_rtk.svg'
import placeholderImageEpubWbtp from '../assets/images/placeholderImageEpubWbtp.svg'
import placeholderImageEpubRtk from '../assets/images/placeholderImageEPubRtk.svg'
import placeholderImageEpubSrd from '../assets/images/placeholderImageEpubSrd.svg'

interface ModuleMetaData {
  logoImgUrl: string
  backgroundImgUrl: string
  // a CSS class that can be used as a hook to create style rules for the current module
  cssNameSpace?: string
  mascotImg: {
    default: string
    tablet?: string
    mobile?: string
  }
  taxonomyHeaderText?: string
  contentSelectorHeaderPrefix?: string
  cardImagePlaceholder: string
}

export const moduleMetadataMap: { [key: string]: ModuleMetaData } = {
  r4r_module_002: {
    logoImgUrl: logoSrd,
    backgroundImgUrl: backgroundSrd,
    cssNameSpace: 'srd',
    mascotImg: {
      default: coachSrd
    },
    taxonomyHeaderText: 'Set',
    contentSelectorHeaderPrefix: 'Cards',
    cardImagePlaceholder: placeholderImageEpubSrd
  },
  r4r_module_003: {
    logoImgUrl: logoRtk,
    backgroundImgUrl: backgroundRtk,
    cssNameSpace: 'rtk',
    mascotImg: {
      default: coachRtk
    },
    taxonomyHeaderText: 'Topic',
    contentSelectorHeaderPrefix: 'Text Set',
    cardImagePlaceholder: placeholderImageEpubRtk
  },
  r4r_module_001: {
    logoImgUrl: logoWbtp,
    backgroundImgUrl: backgroundWbtp,
    cssNameSpace: 'wbtp',
    mascotImg: {
      default: coachPhonics
    },
    taxonomyHeaderText: 'Category',
    cardImagePlaceholder: placeholderImageEpubWbtp
  }
}
