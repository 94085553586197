import React, { useContext, useEffect } from 'react';
import './Home.scss';
import { ModuleSelection } from '../ModuleSelection';
import { Letters2Meaning } from '../Letter2Meaning';
import { updateDumbledataPage } from '../../utilities/update-dumbledata';
import { ActionType, StoreContext } from '../../store/store';
import { MyPicksSection } from '../MyPicks/MyPicksSection';
import { useAuth } from 'authentication-library';

export const Home: React.FC = (): JSX.Element => {
  const { state, dispatch } = useContext(StoreContext);
  const { entitlements } = useAuth();

  useEffect(() => {
    updateDumbledataPage({
      pageName: 'Student Hub Home',
      siteSection: 'Student Hub Home',
      previousPageURL: state?.previousPageURL
    });
    dispatch({ type: ActionType.SET_PREVIOUS_PAGE_URL, data: window.location.href });
  }, []);

  return (
    <div className="student-hub-landing-page">
      <h1 className="sr-only">Ready4Reading Student Hub</h1>
      <main id="main-content">
        <MyPicksSection />
        <h2 className="student-hub-landing-page__module-title">Explore</h2>
        <ModuleSelection />
        {entitlements.includes('R4R_System') && (
          <>
            <h2 className="student-hub-landing-page__module-title">Quiz</h2>
            <Letters2Meaning />
          </>
        )}{' '}
      </main>
    </div>
  );
};
