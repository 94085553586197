export const ModuleNameToModuleId: Record<string, string> = {
  'Wiley Blevins Teaching Phonics': 'WBTP',
  'Read To Know Text Sets': 'RTK',
  'Read To Know': 'RTK',
  'Short Reads Decodables': 'SRD'
};

export enum QueryIdToModuleId {
  'r4r_module_001' = 'WBTP',
  'r4r_module_002' = 'SRD',
  'r4r_module_003' = 'RTK'
}

export enum QueryIdToModuleName {
  'r4r_module_001' = 'Wiley Blevins Teaching Phonics',
  'r4r_module_002' = 'Short Reads Decodables',
  'r4r_module_003' = 'Read To Know'
}

export enum QueryIdToModuleHeader {
  'r4r_module_001' = 'Wiley Blevins Book Selection',
  'r4r_module_002' = 'Short Reads Card Selection',
  'r4r_module_003' = 'Read To Know Content Selection'
}
