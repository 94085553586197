import { getEnvironment } from '../utilities/getEnvironment'

const currentEnv: string = getEnvironment()

export const basePath = !currentEnv
  ? `https://ready4reading-api.prod.micro.scholastic.com/r4r/`
  : `https://ready4reading-api.${currentEnv}.micro.scholastic.com/r4r/`

export const acBasePath = !currentEnv
  ? 'https://activity-center.scholastic.com/'
  : `https://activity-center-${currentEnv}.scholastic.com/`

export const l2mBasePath = !currentEnv
  ? 'https://assessment-service.scholastic.com/'
  : // A2i is using qual instead of qa
    `https://assessment-service-${currentEnv === 'qa' ? 'qual' : currentEnv}.nonprod.scholastic.com/`

export const servicePath = !currentEnv
  ? `https://ready4reading-api.prod.micro.scholastic.com/`
  : `https://ready4reading-api.${currentEnv}.micro.scholastic.com/`
