import axios from 'axios'
import { ModuleId } from '../../types'
import { getServiceUrl, stringifyArrayParams } from '../../utilities'

export type Uuid = string

export interface Skill {
  uuid: Uuid
  name: string
  skillCategoryUuid?: string
}

export interface SkillByModule {
  uuid: ModuleId
  name: string
  image: string
  metadata: string
  skills: Skill[]
}

export interface SkillCategory {
  uuid: Uuid
  name: string
  thumbnailAsset: ThumnbnailAsset
  skills: Skill[]
}

export interface ThumnbnailAsset {
  uuid: Uuid
  assetTypeUuid: string
  filename: string
  description: string
  metadata: string
}

const BASE_URL = getServiceUrl()
export const SKILL_API = `${BASE_URL}/r4r/skillCategory`

function getSkillCategories(moduleUuids: ModuleId[]) {
  const params = stringifyArrayParams({ moduleUuids })
  const uri = `${SKILL_API}?${params}`
  return axios.get<SkillCategory[]>(uri).then((response) => response.data)
}

function getSkillCategory(skillCategoryId: Uuid, moduleIds: ModuleId[]) {
  const params = stringifyArrayParams({ moduleIds })
  const uri = `${SKILL_API}/${skillCategoryId}?${params}`

  return axios.get<SkillByModule[]>(uri).then((response) => response.data)
}

export { getSkillCategories, getSkillCategory }
