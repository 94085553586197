import { ContentSet } from '../types/contentSet'
import { basePath } from './basePath'

/**
 * Fetch a content set (card or text set) for the given textset ID
 * @param textSetId
 */
export const fetchContentSet = async (textSetId: string): Promise<ContentSet> => {
  const response = await fetch(`${basePath}textsets/${textSetId}/texts`)
  if (!response.ok) {
    throw new Error('An error occurred while trying to fetch the content set data')
  }
  return response.json()
}
