import axios from 'axios';
import { getServiceUrl } from '../../utilities';

const SERVICE_URL = getServiceUrl() + '/r4r';

export type AssetApiData = {
  uuid: string;
  type: string;
  url?: string;
  thumbnailUrl?: string;
  thumbnailMetadata?: Record<string, any>; // json data
  title: string;
  description?: string;
  credit_text?: string;
  isbn?: string;
  metadata?: Record<string, any>;
  language?: string;
  captionUrl?: string;
};

export type ModuleAssetData = {
  pageNumber: number;
  totalPages: number;
  pageSize: number;
  numberOfElements: number;
  totalElements: number;
  results: [AssetApiData];
};

function getAsset(uuid: string) {
  return axios.get<AssetApiData>(`${SERVICE_URL}/asset/${uuid}`).then((response) => response.data);
}

function getAssetsByTypeIdAndMetadata(typeId: number, key: string, value: string) {
  return axios
    .get<AssetApiData[]>(`${SERVICE_URL}/asset/type/${typeId}/metadata?key=${key}&value=${value}`)
    .then((response) => response.data);
}

function getAssetsByModule(
  moduleId: string,
  urlModifier: string,
  page: number,
  filter?: string,
  lessonIdFilter?: string
) {
  urlModifier += urlModifier.includes('?') ? '&' : '?';
  let url = `${SERVICE_URL}/asset/module/${moduleId}${urlModifier}page=${page}`;
  if (filter) {
    url += `&filterGroupId=${filter}`;
  }
  if (lessonIdFilter) {
    url += `&lessonId=${lessonIdFilter}`;
  }
  return axios.get<ModuleAssetData>(url).then((response) => response.data);
}

function getLessonsByModule(moduleId: string, size?: number) {
  const sizeModification = size ? `?size=${size}` : '';
  const uri = `${SERVICE_URL}/asset/module/${moduleId}/lessons${sizeModification}`;
  return axios.get<ModuleAssetData>(uri).then((response) => response.data);
}

export { getAsset, getAssetsByTypeIdAndMetadata, getAssetsByModule, getLessonsByModule };
