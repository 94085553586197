import React, { useState } from 'react';
import './VideoModal.scss';
import * as Dialog from '@radix-ui/react-dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { AblePlayer } from '../AblePlayer/AblePlayer';
import iconPlay from '../../assets/images/icon_play.svg';
import { removeExistingAblePlayerScript } from '../../utilities/domManipulation';
import placeholderImageRtk from '../../assets/images/placeholderImageVideoRtk.svg';

interface VideoModalProps {
  assetUrl: string;
  thumbnailUrl: string;
  thumbnailAlt: string;
  videoId: string;
  captionUrl?: string;
  descriptionUrl?: string;
  analyticsCall?: () => void;
  title?: string;
}

export const VideoModal: React.FC<VideoModalProps> = ({
  assetUrl,
  thumbnailUrl,
  thumbnailAlt,
  analyticsCall,
  ...rest
}): JSX.Element => {
  const [shouldHideUi, setShouldHideUi] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const videoContentId = `video_modal_content_${rest.videoId}`;

  return (
    <Dialog.Root
      open={open}
      onOpenChange={(open) => {
        setShouldHideUi(false);
        if (open && analyticsCall) {
          analyticsCall();
        }
        if (!open) {
          window.speechSynthesis?.cancel();
          removeExistingAblePlayerScript(rest.videoId);
        }
      }}
    >
      <Dialog.Trigger asChild>
        <button
          className="student-hub-video-modal__open-btn"
          aria-label={`${rest.title ?? 'open the'} video`}
          aria-expanded={undefined}
          aria-controls={open ? videoContentId : undefined}
          onClick={() => setOpen(!open)}
        >
          <img
            className="student-hub-video-modal__icon student-hub-video-modal__icon--center"
            src={iconPlay}
            alt=""
          />
          <img
            className="student-hub-video-modal__poster-image"
            src={thumbnailUrl}
            alt=""
            data-testid="poster-img"
            width="324"
            height="190"
            onError={(e: React.SyntheticEvent<HTMLImageElement>) =>
              ((e.target as HTMLImageElement).src = placeholderImageRtk)
            }
          />
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="student-hub-video-modal__overlay" />

        <Dialog.Content
          onInteractOutside={(e) => /* istanbul ignore next */ {
            /**
             * ignored the onInteractOutside event code block from Jest coverage(with "istanbul ignore next" comment) report as jest was not able to trigger/mock
             * the event.
             */
            const ableModalIsAncestor = (e.target as HTMLDivElement).closest('.able-modal-dialog');
            const ableModals = document.querySelectorAll('.able-modal-dialog');
            const ableModalIsVisible: boolean = [...ableModals].some((modal) => modal.ariaHidden === 'false');

            if (ableModalIsVisible || ableModalIsAncestor) {
              e.preventDefault();
            }
          }}
          className="student-hub-video-modal__content"
          id={videoContentId}
          aria-describedby={undefined}
          aria-labelledby={undefined}
          aria-label={`${rest.title ?? ''} video player`}
        >
          {
            <AblePlayer
              playerId={'player__' + rest.videoId}
              playerClass="student-hub-video-modal__player"
              videoMp4Src={assetUrl}
              captionSrcUrl={rest.captionUrl}
              descriptionSrcUrl={rest.descriptionUrl}
              playerTheme="2020"
              preLoad={true}
            />
          }

          <Dialog.Close asChild>
            <button
              className={classNames('student-hub-video-modal__btn', 'student-hub-video-modal__close-btn', {
                'student-hub-video-modal__btn--hide-outline': shouldHideUi
              })}
              aria-label="close the video"
              onClick={() => setOpen(!open)}
            >
              <FontAwesomeIcon
                data-testid="svg-close-icon"
                className={classNames(
                  'student-hub-video-modal__icon',
                  'student-hub-video-modal__icon-close',
                  {
                    'student-hub-video-modal__icon--hide': shouldHideUi
                  }
                )}
                icon={faTimesCircle}
              />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
