import React, { useContext, useEffect, useState } from 'react';
import { CardList } from '../CardList';
import { ContentSet, ContentText, RelatedContent } from '../../types/contentSet';
import { useParams, useLocation } from 'react-router-dom';
import './ContentSelection.scss';
import { fetchContentSet } from '../../api/ContentSetApi';
import { moduleMetadataMap } from '../../utilities/moduleMetadataMap';
import { ActionType, StoreContext } from '../../store/store';
import { PageHeader } from '../PageHeader';
import { updateDumbledataPage } from '../../utilities/update-dumbledata';
import { ModuleNameToModuleId, QueryIdToModuleHeader } from '../../utilities/ModuleEnums';

export const ContentSelection: React.FC = (): JSX.Element => {
  const [contentSet, setContentSet] = useState<ContentSet>();
  const [texts, setTexts] = useState<ContentText[]>([]);
  const [relatedContent, setRelatedContent] = useState<RelatedContent[]>([]);
  const { textSetId } = useParams();
  const location = useLocation();
  const { state, dispatch } = useContext(StoreContext);
  localStorage.setItem('supertitle', location?.state?.supertitle || '');

  useEffect(() => {
    const fetchData = async () => {
      if (!textSetId) {
        return;
      }
      try {
        const contentData = await fetchContentSet(textSetId);
        setContentSet(contentData);
        dispatch({
          type: ActionType.SET_CURRENT_MODULE_ID,
          data: contentData.moduleId
        });
        //DumbleData set
        const pageName = `Content Selection - ${contentData.textSetTitle}`;
        const siteSection = contentData.moduleTitle
          ? `Student Hub - ${ModuleNameToModuleId[contentData.moduleTitle]}`
          : `Student Hub`;
        updateDumbledataPage({ pageName, siteSection, previousPageURL: state?.previousPageURL });
      } catch (e) {
        updateDumbledataPage({
          pageName: 'Content Selection',
          siteSection: 'Student Hub',
          previousPageURL: state?.previousPageURL
        });
        console.error(e);
      } finally {
        dispatch({ type: ActionType.SET_PREVIOUS_PAGE_URL, data: window.location.href });
      }
    };

    fetchData().then();
  }, [textSetId]);

  useEffect(() => {
    if (contentSet) {
      setTexts(contentSet.texts);
      setRelatedContent(contentSet.relatedContent);
    }
  }, [contentSet]);

  return (
    <>
      <h1 className="sr-only">
        {QueryIdToModuleHeader[contentSet?.moduleId as keyof typeof QueryIdToModuleHeader]}
      </h1>
      {contentSet && (
        <section
          className={`student-hub-content-selection ${moduleMetadataMap[contentSet.moduleId].cssNameSpace}`}
        >
          <PageHeader
            logoImgUrl={moduleMetadataMap[contentSet.moduleId].logoImgUrl}
            moduleTitle={contentSet.moduleTitle}
            backBtnUrl={`/category-selector/${contentSet.moduleId}`}
          />
          <main id="main-content">
            <h2 className="student-hub-content-selection__title">
              {`${location?.state?.supertitle || localStorage.getItem('supertitle')} ${
                contentSet.textSetTitle
              }`}
            </h2>
            <CardList textSets={texts} relatedContent={relatedContent} showAssetIcon hrefIsExternal />
          </main>
        </section>
      )}
    </>
  );
};
