import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { StateProvider } from './store/store';
import './App.scss';
import { Home } from './components/Home';
import { GlobalFooter, GlobalHeader } from 'component-library';
import { ContentSelection } from './components/ContentSelection';
import { TopicSelection } from './components/TopicSelection';
import { BackgroundImage } from './components/BackgroundImage';
import backgroundDesktop from './assets/images/background_sky.svg';
import backgroundTablet from './assets/images/background_sky_tablet.svg';
import backgroundMobile from './assets/images/background_sky_mobile.svg';

import { TeacherBanner } from './components/TeacherBanner';
import { emitDirectCall, updateStaticDumbleData } from './utilities/update-dumbledata';
import { getSignedCookies } from 'api-library';
import * as Cookies from 'js-cookie';

function App() {
  const [backgroundImgUrl, setBackgroundImgUrl] = useState<string>('');

  useEffect(() => {
    getSignedCookies().catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const getBackgroundImgUrl = () => {
      let imgUrl;
      if (window.innerWidth < 768) {
        imgUrl = backgroundMobile;
      } else if (window.innerWidth < 992) {
        imgUrl = backgroundTablet;
      } else {
        imgUrl = backgroundDesktop;
      }
      setBackgroundImgUrl(imgUrl);
    };
    getBackgroundImgUrl();
    window.addEventListener('resize', getBackgroundImgUrl);

    return () => {
      window.removeEventListener('resize', getBackgroundImgUrl);
    };
  }, []);

  useEffect(() => {
    updateStaticDumbleData();
  }, []);

  // conditional disables right clicks and triggers a left click if user is student
  useEffect(() => {
    const handleContextMenuClick = (e: Event) => {
      const userDetails = Cookies.getJSON('R4R');
      //Allow right click
      if (userDetails && userDetails.role === 'educator') {
        return;
      } else {
        e.preventDefault();
        const target = e.target as HTMLElement | Element;

        if (target instanceof HTMLElement) {
          target.click();
          return;
        }
        // target is an Element and doesn't implement a click() method, check if it has <button> or <a> parent and call its click
        (target.closest('button') || target.closest('a'))?.click();
      }
    };
    document.addEventListener('contextmenu', handleContextMenuClick);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenuClick);
    };
  }, []);

  return (
    <div style={{ background: `url(${backgroundImgUrl}) no-repeat top center/cover` }}>
      <div className="student-hub-app">
        <GlobalHeader
          onIconClick={() => {
            emitDirectCall('r4rIcon');
          }}
        />
        <div className="primary-content">
          <StateProvider>
            <TeacherBanner />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/category-selector/:moduleId" element={<TopicSelection />} />
              <Route path="/content-selector/:textSetId" element={<ContentSelection />} />
            </Routes>
            <BackgroundImage />
          </StateProvider>
        </div>
        <GlobalFooter />
      </div>
    </div>
  );
}

export default App;
