import React from 'react';
import cookies from 'js-cookie';
import { ApplicationSwitcher as ApplicationSwitcherComponent } from '@scholastic/dp-application-navigation';

import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

import './ApplicationSwitcher.scss';

export default function ApplicationSwitcher() {
  const isDev = process.env.NODE_ENV === 'development';
  if (isDev) {
    return null;
  }

  return (
    <ErrorBoundary fallback={<></>}>
      <ApplicationSwitcherComponent
        onLogout={() => {
          cookies.remove('R4R');
          return true;
        }}
        cookieName="sdm_nav_ctx"
        borderColor="#dcdcdc"
        color="#333333"
      />
    </ErrorBoundary>
  );
}
