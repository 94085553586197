/**
 * returns "." + env for the lowers, empty string for prod
 */
export function getEnvironment(): string {
  const currentSite = window.location.href

  if (currentSite.includes('localhost')) {
    return 'dev'
  }
  const envRegex = /student-hub.(.*?)\.ready4reading.scholastic.com/
  return currentSite.match(envRegex)?.[1] || ''
}
