import cookies from 'js-cookie';
import { datadogRum } from '@datadog/browser-rum';

export function setDatadogUser() {
  const { user_id } = cookies.getJSON('R4R') || { user_id: null };
  if (!!user_id) {
    datadogRum.setUser({
      id: user_id
    });
  }
}

export function addDatadogAction(name: string, context?: object) {
  datadogRum.addAction(name, context);
}
