import { basePath } from './basePath'
import * as Cookies from 'js-cookie'

export const fetchLtiParams = async (appId: string, customParams: object = {}): Promise<any> => {
  let customParamsQueryString = new URLSearchParams(Object.entries(customParams)).toString()
  customParamsQueryString = customParamsQueryString ? `/?${decodeURIComponent(customParamsQueryString)}` : ''
  const response = await fetch(basePath + `lti/payload/appCode/${appId}${customParamsQueryString}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: getReadyForReadingCookie()
  })

  if (!response.ok) {
    throw new Error('An error occurred while fetching the lti parameters')
  }

  return response.json()
}

const getReadyForReadingCookie = () => {
  return Cookies.get('R4R')
}
