import React from 'react';
import IconButton from '../IconButton/IconButton';
import SkipNavigation from '../SkipNavigation/SkipNavigation';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import ApplicationSwitcher from '../ApplicationSwitcher/ApplicationSwitcher';
import r4rLogo from '../../assets/images/r4r_logo.png';
import HomeIcon from '../../assets/images/home_icon.svg';

import './GlobalHeader.scss';

interface GlobalHeaderProps {
  children?: JSX.Element;
  onIconClick?: () => void;
}

/**
 * Renders the global header for the R4R application. It contains the SDM navbar.
 *
 * @example
 * <GlobalHeader/>
 *
 */
const GlobalHeader = ({ children, onIconClick }: GlobalHeaderProps) => {
  const navigate = useNavigate();

  const className = 'global-header';
  return (
    <header>
      <div className={className}>
        <SkipNavigation elementId="main-content" />
        <div className={`${className}__home-icon-wrapper ${className}__grid-cell`}>
          {!useMatch('/') && (
            <Link aria-label="Ready 4 Reading Home" to="/">
              <img src={HomeIcon} alt="" />
            </Link>
          )}
        </div>
        <div className={`${className}__wrapper`}>
          <div className={`${className}__mobile-nav-menu-wrapper ${className}__grid-cell`}>
            <IconButton
              ariaLabel="Scholastic Digital Manager Navigation Menu"
              icon="Hamburger"
              hideBorder={true}
            ></IconButton>
          </div>
          <div className={`${className}__logo-wrapper ${className}__grid-cell`}>
            <Link
              to={'/'}
              data-testid={`${className}__logo-link`}
              className={`${className}__link`}
              onClick={onIconClick}
            >
              <img
                data-testid={`${className}__logo`}
                className={`${className}__logo`}
                src={r4rLogo}
                alt="Scholastic Ready 4 Reading"
              />
            </Link>
          </div>

          <div className={`${className}__sdm-wrapper ${className}__grid-cell`}>
            <ApplicationSwitcher />
          </div>
        </div>
      </div>
      {children}
    </header>
  );
};

export default GlobalHeader;
