import axios from 'axios';
import { getServiceUrl, stringifyArrayParams } from '../../utilities';
import { ModuleId } from '../../types';

export interface Module {
  title: string;
  id: string;
}

export interface ModuleCategories {
  moduleUuid: string;
  categories: string[];
}

export interface FilterGroup {
  assetUuid: string;
  title: string;
}

export interface ModuleFilterGroups {
  title: string;
  groupId: string;
  nestedGroups: FilterGroup[];
}

const BASE_URL = getServiceUrl();
export const MODULE_API = `${BASE_URL}/r4r/module/`;

function getModules() {
  return axios.get<Module[]>(MODULE_API).then((response) => response.data);
}

function getFilterGroupsByModule(moduleId: string) {
  const uri = `${MODULE_API}${moduleId}/filterGroups`;
  return axios.get<ModuleFilterGroups[]>(uri).then((response) => response.data);
}

function getCategoriesbyModule(moduleUuids: ModuleId[]) {
  const params = stringifyArrayParams({ moduleUuids });
  const uri = `${MODULE_API}categories?${params}`;
  return axios.get<ModuleCategories[]>(uri).then((response) => response.data);
}

export { getModules, getCategoriesbyModule, getFilterGroupsByModule };
