import React, { useContext, useEffect, useState } from 'react';
import { TopicSet } from '../../types/topicSet';
import './TopicSelection.scss';
import { CardList } from '../CardList';
import { fetchTopicSets } from '../../api/TopicSetApi';
import { moduleMetadataMap } from '../../utilities/moduleMetadataMap';
import { useParams } from 'react-router-dom';
import { ActionType, StoreContext } from '../../store/store';
import { PageHeader } from '../PageHeader';
import { useAuth } from 'authentication-library';
import { updateDumbledataPage } from '../../utilities/update-dumbledata';
import { QueryIdToModuleId, QueryIdToModuleName } from '../../utilities/ModuleEnums';

const CARD_HREF = '/content-selector/';

export const TopicSelection: React.FC = (): JSX.Element => {
  const [topicSet, setTopicSet] = useState<TopicSet[]>([]);
  const { moduleId } = useParams();
  const { state, dispatch } = useContext(StoreContext);
  const { modules } = useAuth();

  useEffect(() => {
    if (moduleId) {
      const moduleName = QueryIdToModuleId[moduleId as keyof typeof QueryIdToModuleId];
      updateDumbledataPage({
        pageName: `Category Selection - ${moduleName}`,
        siteSection: `Student Hub - ${moduleName}`,
        previousPageURL: state?.previousPageURL
      });
      dispatch({ type: ActionType.SET_PREVIOUS_PAGE_URL, data: window.location.href });
    }
  }, [moduleId]);

  useEffect(() => {
    const fetchData = async () => {
      if (moduleId && !topicSet.length) {
        try {
          const topics = await fetchTopicSets(moduleId);
          setTopicSet(topics);
          dispatch({
            type: ActionType.SET_CURRENT_MODULE_ID,
            data: topics[0].moduleId
          });
        } catch (e) {
          console.error(e);
        }
      }
    };
    fetchData().then();
  }, [moduleId, dispatch, topicSet.length]);

  let headerText: string | undefined = undefined;
  if (moduleId) {
    headerText = moduleMetadataMap[moduleId].taxonomyHeaderText;
  }

  return (
    <>
      {headerText && (
        <h1 className="sr-only">
          {`${QueryIdToModuleName[moduleId as keyof typeof QueryIdToModuleName]} ${headerText} Selection`}
        </h1>
      )}
      {topicSet.map((module, idx) => (
        <section key={`module-${idx}`} className="student-hub-topic-selection">
          <PageHeader
            logoImgUrl={moduleMetadataMap[module.moduleId].logoImgUrl}
            moduleTitle={module.moduleTitle}
            shouldHideBackBtn={modules.length === 1}
          />
          <main id="main-content">
            {headerText && <h2 className="student-hub-topic-selection__h2">{`Choose a ${headerText}:`}</h2>}
            <CardList textSets={module.textSets} href={CARD_HREF} analyticsLabel="CategoryTile" />
          </main>
        </section>
      ))}
    </>
  );
};
