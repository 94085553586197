import axios from 'axios'
import { getServiceUrl } from '../../utilities'

const SERVICE_URL = getServiceUrl() + '/r4r'

export type LimelightUrl = {
  playbackUrl: string
}

function getLimelightURL(mediaId: string) {
  return axios
    .get<LimelightUrl>(`${SERVICE_URL}/limelight/signedUrl/media/${mediaId}`)
    .then((response) => response.data)
}

export { getLimelightURL }
