import React, {
  Children,
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  LiHTMLAttributes,
  PropsWithChildren,
  ReactElement,
  cloneElement,
  forwardRef,
  isValidElement,
  useRef,
  useState,
  useEffect
} from 'react';
import { HorizontalScrollArea, HorizontalScrollAreaProps } from './HorizontalScrollArea/HorizontalScrollArea';
import classNames from 'classnames';
import ChevronRightIcon from '../../assets/images/right_caret.svg';
import ChevronDisableIcon from '../../assets/images/right_caret_disable.svg';
import { useDebouncedCallback } from 'use-debounce';
import { Nullable } from '../../utils/nullable';
import isDeepEqual from 'fast-deep-equal';
import { useComponentDidMount } from '../../lib/hooks/UseComponentDidMount/useComponentDidMount';
import styles from './HorizontalOverflowList.module.scss';
import { Button } from '@scholastic/mockingjay';

interface HorizontalOverflowListProps extends PropsWithChildren {
  itemGap?: string;
  scrollProps?: HorizontalScrollAreaProps;
  className?: string;
  ariaLabel?: string;
}

const Root = ({
  children,
  scrollProps = {},
  className,
  ariaLabel = 'Horizontal Overflow List'
}: HorizontalOverflowListProps) => {
  const [showArrowsState, setShowArrowsState] = useState<{
    shouldShowLeft: boolean;
    shouldShowRight: boolean;
  }>({ shouldShowLeft: false, shouldShowRight: false });

  const [arrowDisableState, setArrowDisableState] = useState<{
    shouldDisableLeft: boolean;
    shouldDisableRight: boolean;
  }>({ shouldDisableLeft: false, shouldDisableRight: false });

  const listItemRefs = useRef<HTMLLIElement[]>([]);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const calculateAndSetShowArrowsStateRef = useRef<() => void>(() => {
    let isOverflow = false;
    let isScrollableLeft = false;
    let isScrollableRight = false;

    if (scrollContainerRef?.current) {
      isOverflow = scrollContainerRef.current.offsetWidth < scrollContainerRef.current.scrollWidth;

      const offset = 10;

      isScrollableLeft = scrollContainerRef.current.scrollLeft > offset;
      isScrollableRight =
        scrollContainerRef.current.offsetWidth + scrollContainerRef.current.scrollLeft + offset <
        scrollContainerRef.current.scrollWidth;
    }

    const newShowArrowsState = {
      shouldShowLeft: isOverflow,
      shouldShowRight: isOverflow
    };
    const newArrowDisableState = {
      shouldDisableLeft: isOverflow && isScrollableLeft,
      shouldDisableRight: isOverflow && isScrollableRight
    };

    setShowArrowsState((currentShowArrowsState) =>
      !isDeepEqual(currentShowArrowsState, newShowArrowsState) ? newShowArrowsState : currentShowArrowsState
    );

    setArrowDisableState((currentArrowDisableState) =>
      !isDeepEqual(currentArrowDisableState, newArrowDisableState)
        ? newArrowDisableState
        : currentArrowDisableState
    );
  });

  const debouncedCalculateAndSetShowArrowsState = useDebouncedCallback(
    calculateAndSetShowArrowsStateRef.current,
    200
  );

  useComponentDidMount(() => {
    window.addEventListener('resize', debouncedCalculateAndSetShowArrowsState);

    return () => {
      window.removeEventListener('resize', debouncedCalculateAndSetShowArrowsState);
    };
  });

  useEffect(() => {
    calculateAndSetShowArrowsStateRef.current();
  }, [children]);

  const childrenArray = Children.toArray(children);
  return (
    <section className={classNames(styles.horizontalOverflowList, className)} aria-label={ariaLabel}>
      {showArrowsState.shouldShowLeft && (
        <div className={classNames(styles.horizontalOverflowListLeftButton)}>
          <Button
            aria-hidden
            data-testid="previous books"
            tabIndex={-1}
            aria-label="View previous books"
            className={classNames(styles.horizontalOverflowListLeftButtonIcon)}
            onClick={() => scrollToPreviousPage(scrollContainerRef.current, listItemRefs.current)}
            variant="secondary"
            disabled={!arrowDisableState.shouldDisableLeft}
          >
            <img
              aria-hidden
              className={classNames(
                !arrowDisableState.shouldDisableLeft
                  ? styles.horizontalOverflowListLeftDisableButtonRotate
                  : styles.horizontalOverflowListLeftButtonRotate
              )}
              src={!arrowDisableState.shouldDisableLeft ? ChevronDisableIcon : ChevronRightIcon}
            />
          </Button>
        </div>
      )}
      <HorizontalScrollArea
        {...scrollProps}
        contentScrollbarSpacing="22px"
        ref={scrollContainerRef}
        onScroll={debouncedCalculateAndSetShowArrowsState}
      >
        <ul className={classNames(styles.horizontalOverflowListScrollArea)}>
          {Children.map(childrenArray, (Child, index) => {
            if (!isValidElement<HorizontalOverflowListItemProps>(Child) || Child.type !== Item) {
              throw new Error('Children must be ListItem components');
            }

            const HorizontalOverflowListItemChild: ReactElement<
              HorizontalOverflowListItemProps & ComponentPropsWithRef<'li'>
            > = Child;

            return cloneElement(HorizontalOverflowListItemChild, {
              ...HorizontalOverflowListItemChild.props,
              ref: (node: HTMLLIElement) => (listItemRefs.current[index] = node)
            });
          })}
        </ul>
      </HorizontalScrollArea>

      {showArrowsState.shouldShowRight && (
        <div className={classNames(styles.horizontalOverflowListRightButton)}>
          <Button
            aria-hidden
            data-testid="next books"
            tabIndex={-1}
            aria-label="View additional books"
            className={classNames(styles.horizontalOverflowListRightButtonIcon)}
            onClick={() => scrollToNextPage(scrollContainerRef.current, listItemRefs.current)}
            variant="secondary"
            disabled={!arrowDisableState.shouldDisableRight}
          >
            <img
              className={classNames(
                !arrowDisableState.shouldDisableRight
                  ? styles.horizontalOverflowListRightButtonDisableRotate
                  : styles.horizontalOverflowListRightButtonRotate
              )}
              src={!arrowDisableState.shouldDisableRight ? ChevronDisableIcon : ChevronRightIcon}
              aria-hidden
            />
          </Button>
        </div>
      )}
    </section>
  );
};

type HorizontalOverflowListItemProps = LiHTMLAttributes<HTMLLIElement> & ComponentPropsWithoutRef<'li'>;

const Item = forwardRef<HTMLLIElement, HorizontalOverflowListItemProps>((props, forwardedRef) => (
  <li className={classNames(styles.horizontalOverflowListScrollAreaItems)} {...props} ref={forwardedRef} />
));

const scrollToPreviousPage = (containerNode: Nullable<HTMLDivElement>, listItemNodes: HTMLLIElement[]) => {
  const gapBetween = getGapBetweenItems(listItemNodes);

  const firstLeftOverflowItem = [...listItemNodes]
    .reverse()
    .find((node) => node.getBoundingClientRect().left - containerNode.getBoundingClientRect().left < 0);

  const scrollToX = firstLeftOverflowItem
    ? firstLeftOverflowItem.offsetLeft -
      containerNode.offsetWidth +
      firstLeftOverflowItem.offsetWidth +
      gapBetween
    : 0;

  containerNode.scrollTo({
    left: scrollToX,
    behavior: 'smooth'
  });
};

const scrollToNextPage = (containerNode: Nullable<HTMLDivElement>, listItemNodes: HTMLLIElement[]) => {
  const firstRightOverflowItem = listItemNodes.find(
    (node) =>
      node.getBoundingClientRect().left - containerNode.getBoundingClientRect().left + node.offsetWidth >
      containerNode.offsetWidth
  );

  const gapBetween = getGapBetweenItems(listItemNodes);

  const scrollToX = firstRightOverflowItem
    ? firstRightOverflowItem.offsetLeft - gapBetween
    : containerNode.scrollWidth;

  containerNode.scrollTo({
    left: scrollToX,
    behavior: 'smooth'
  });
};

const getGapBetweenItems = (listItemNodes: HTMLLIElement[]) => {
  if (listItemNodes.length <= 1) {
    return 0;
  }

  const firstItemRect = listItemNodes[0].getBoundingClientRect();
  const secondItemRect = listItemNodes[1].getBoundingClientRect();

  return secondItemRect.x - firstItemRect.x - listItemNodes[0].offsetWidth;
};

const HorizontalOverflowList = {
  Root,
  Item
};

export default HorizontalOverflowList;
