import React from 'react';
import './SkipNavigation.scss';

interface SkipNavigationProps {
  elementId: string;
}

/**
 * Invisible component that helps accessible users skip to main page content
 * Visible when focused on.
 *
 * @param elementId - id of the element to focus on
 *
 * @returns - Skip Navigation component
 */

const SkipNavigation = ({ elementId }: SkipNavigationProps) => {
  const className = 'skip-navigation';
  return (
    <a className={`${className}`} href={`#${elementId}`} data-testid={`${className}`}>
      Skip to Main Content
    </a>
  );
};

export default SkipNavigation;
