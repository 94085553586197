import { ContentModule } from '../types/contentModule'
import { basePath } from './basePath'

/**
 * Fetch a list of all the modules (aka content packs) the user is entitled to
 */
export const fetchAllContentModules = async (): Promise<ContentModule[]> => {
  const response = await fetch(`${basePath}module/`)
  if (!response.ok) {
    throw new Error('An error occurred while trying to fetch the module data')
  }

  return response.json()
}
