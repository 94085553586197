import React from 'react';
import { TextSet } from '../../types/topicSet';
import { AssetCard } from '../AssetCard';
import './CardList.scss';
import { ContentText, RelatedContent } from '../../types/contentSet';

interface CardListProps {
  textSets: TextSet[] | ContentText[];
  href?: string;
  hrefIsExternal?: boolean;
  // toggle icon of content type (book, video, etc)
  showAssetIcon?: boolean;
  relatedContent?: RelatedContent[];
  analyticsLabel?: string;
}

export const CardList: React.FC<CardListProps> = ({
  textSets,
  href,
  hrefIsExternal = false,
  relatedContent,
  showAssetIcon,
  analyticsLabel
}): JSX.Element => {
  return (
    <div className="student-hub-cardlist__container">
      {relatedContent
        ?.sort((a, b) => {
          if (a.sequence === b.sequence && a.language && b.language) {
            return a.language < b.language ? -1 : a.language > b.language ? 1 : 0;
          } else {
            return a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0;
          }
        })
        .map((content, idx) => (
          <AssetCard key={`relatedCard-${idx}`} {...content} showAssetIcon={showAssetIcon} />
        ))}
      {textSets
        .sort((a, b) => {
          return a.sequence < b.sequence ? -1 : a.sequence > b.sequence ? 1 : 0;
        })
        .map((textSet, idx) => (
          <AssetCard
            href={href}
            hrefIsExternal={hrefIsExternal}
            key={`assetcard-${idx}`}
            {...textSet}
            showAssetIcon={showAssetIcon}
            analyticsLabel={analyticsLabel}
          />
        ))}
    </div>
  );
};
