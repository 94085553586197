import React, { createContext, Dispatch, ReactNode, Reducer, useReducer } from 'react';

export enum ActionType {
  LOGIN = 'LOGIN',
  SET_CURRENT_MODULE_ID = 'SET_CURRENT_MODULE_ID',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_PREVIOUS_PAGE_URL = 'SET_PREVIOUS_PAGE_URL'
}

interface Action {
  type: ActionType;
  data: any;
}

interface Store {
  [key: string]: any;

  dispatch: (action: Action) => void;
}

const initialState: Store = {
  currentModuleId: undefined,
  currentUserDetails: undefined,
  previousPageURL: undefined,
  dispatch: () => null
};

const StoreContext = createContext<Store>(initialState);

const dispatchMiddleware = (dispatch: Dispatch<Action>) => {
  return (action: Action) => {
    switch (action.type) {
      case ActionType.LOGIN:
        /**
         * Placeholder case for demo.
         * Additional data manipulation area before dispatch.
         * dispatch(action);
         */
        break;
      default:
        dispatch(action);
        return;
    }
  };
};

export const reducer: Reducer<Store, Action> = (state, action) => {
  switch (action.type) {
    case ActionType.SET_CURRENT_MODULE_ID:
      return action.data === state.currentModuleId
        ? state
        : {
            ...state,
            currentModuleId: action.data
          };

    case ActionType.SET_CURRENT_USER:
      // check for deep equality to prevent infinite render loop
      return JSON.stringify(action.data) === JSON.stringify(state.currentUserDetails)
        ? state
        : {
            ...state,
            currentUserDetails: action.data
          };
    case ActionType.SET_PREVIOUS_PAGE_URL:
      return action.data === state.previousPageURL
        ? state
        : {
            ...state,
            previousPageURL: action.data
          };
    default:
      throw new Error();
  }
};

const StateProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={{ state, dispatch: dispatchMiddleware(dispatch) }}>
      {children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StateProvider };
