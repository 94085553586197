/**
 * Returns a formatted query string based on the provided params.
 * Assumes that the params are in the form of {key1: [value1, value2, value3]}
 * @param params {[string]: string[]}
 * @returns string
 */
export function stringifyArrayParams(params: Record<string, string[]>) {
  const keyName = Object.keys(params)[0]
  return params[keyName].map((param) => `${keyName}=${param}`).join('&')
}

/**
 * Returns the API URL based on the global window.location object.
 * Assumes that the URL structure matches the following pattern:
 *  http(s)://(<app-code>.)<environment>.ready4reading.scholastic.com
 * @returns string
 */
export function getServiceUrl(): string {
  const environment = getEnvironment(window.location.hostname)
  return `https://ready4reading-api${environment}.micro.scholastic.com`
}

/**
 * Returns the environment name based on the global window.location object.
 * Assumes that the URL structure matches the following pattern:
 * http(s)://(<app-code>.)<environment>.ready4reading.scholastic.com
 * @param hostname string
 * @returns string
 */
export function getEnvironment(hostname: string): string {
  switch (true) {
    case hostname === 'localhost':
    case hostname.includes('localhost'):
    case hostname.includes('dev'):
      return '.dev'
    case hostname.includes('qa'):
      return '.qa'
    case hostname.includes('stage'):
      return '.stage'
    case hostname.includes('perf'):
      return '.perf'
    default:
      return '.prod'
  }
}
