import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { emitDirectCall } from '../../utilities/update-dumbledata';

interface ModuleCardProps {
  id: string;
  title: string;
  cssNameSpace?: string;
  mascotImgUrl?: string;
  logoImgUrl: string;
}

export const ModuleCard: React.FC<ModuleCardProps> = ({
  id,
  title,
  cssNameSpace,
  mascotImgUrl,
  logoImgUrl
}) => {
  return (
    <article
      className={classNames('student-hub-modules__item', `student-hub-modules__item--${cssNameSpace}`)}
    >
      <Link
        to={`/category-selector/${id}`}
        data-testid={'module-card'}
        onClick={() => emitDirectCall('moduleTile', { name: title })}
        aria-label={title}
      >
        <div className="student-hub-modules__item-card-content">
          <div className="student-hub-modules__item-logo-container">
            <img className="student-hub-modules__item-logo" src={logoImgUrl} alt={title} />
          </div>
          <div className="student-hub-modules__item-mascot-container">
            <img
              className="student-hub-modules__item-mascot"
              src={mascotImgUrl}
              alt=""
              data-testid={`mascot-${id}`}
            />
          </div>
        </div>
        <div className="student-hub-modules__item-title">{title}</div>
      </Link>
    </article>
  );
};
