import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import './AssetCard.scss';
import iconBook from '../../assets/images/icon_book.svg';
import iconVideo from '../../assets/images/icon_video.svg';
import { VideoModal } from '../VideoModal';
import { LazyLoadImage } from 'component-library';
import { makeLtiLaunchRequest } from '../../api/LtiLaunchApi';
import uniqid from 'uniqid';
import { moduleMetadataMap } from '../../utilities/moduleMetadataMap';
import { StoreContext } from '../../store/store';
import { emitDirectCall } from '../../utilities/update-dumbledata';

interface AssetCardProps {
  thumbnailUrl: string;
  thumbnailAlt: string;
  title: string;
  subtitle?: string;
  supertitle?: string;
  uuid: string;
  href?: string;
  hrefIsExternal?: boolean;
  showAssetIcon?: boolean;
  assetType?: string;
  assetUrl?: string;
  language?: string;
  analyticsLabel?: string;
  captionUrl?: string;
}

const mapIconToAssetType: { [key: string]: string } = {
  VIDEO: iconVideo
};

const supportedLanguagesMap: { [key: string]: string } = {
  en: 'English',
  es: 'Español'
};

export const AssetCard: React.FC<AssetCardProps> = ({
  thumbnailUrl,
  thumbnailAlt,
  title,
  subtitle,
  supertitle,
  uuid,
  showAssetIcon = false,
  assetUrl,
  assetType,
  language,
  href = '',
  hrefIsExternal = false,
  analyticsLabel,
  captionUrl
}): JSX.Element => {
  const { textSetId } = useParams();
  const { state } = useContext(StoreContext);
  const handleError = (e: Event) => {
    (e.target as HTMLImageElement).src = moduleMetadataMap[state.currentModuleId].cardImagePlaceholder;
  };

  const handleDirectCall = () => {
    if (analyticsLabel) {
      emitDirectCall(analyticsLabel, { name: title });
    } else {
      if (assetType === 'VIDEO') {
        emitDirectCall('studentVideo', { name: title });
      } else {
        emitDirectCall('studentText', { name: title, id: uuid, teacherRec: false });
      }
    }
  };

  const captionSrcUrl = captionUrl ?? '';

  const callbackUrl = `${process.env.REACT_APP_STUDENT_HUB_URL}/content-selector/${textSetId}`;

  const cardImg =
    hrefIsExternal && assetType ? (
      <button
        aria-label={`go to ${title}`}
        onClick={() => {
          makeLtiLaunchRequest({
            appId: 'ActivityCenter',
            epubId: uuid,
            customParams: { callbackUrl }
          });
          handleDirectCall();
        }}
      >
        <LazyLoadImage onError={handleError} src={thumbnailUrl} alt={thumbnailAlt} width="324" height="190" />
      </button>
    ) : (
      <Link
        aria-label={`go to ${title}`}
        to={hrefIsExternal ? '#!' : `${href}${uuid}`}
        state={{ supertitle: supertitle }}
        onClick={() => {
          if (hrefIsExternal && !assetType) {
            makeLtiLaunchRequest({
              appId: 'ActivityCenter',
              epubId: uuid,
              customParams: { callbackUrl }
            });
          }
          handleDirectCall();
        }}
      >
        <LazyLoadImage onError={handleError} src={thumbnailUrl} alt={thumbnailAlt} width="324" height="190" />
      </Link>
    );

  return (
    <div className="student-hub-asset-card" lang={language}>
      {assetType === 'VIDEO' ? (
        <>
          {language && (
            <p className="student-hub-asset-card__language-tag">{supportedLanguagesMap[language]}</p>
          )}
          {assetUrl && (
            <VideoModal
              assetUrl={assetUrl}
              thumbnailUrl={thumbnailUrl}
              thumbnailAlt={thumbnailAlt}
              captionUrl={captionSrcUrl}
              videoId={uniqid()}
              analyticsCall={handleDirectCall}
              title={title}
            />
          )}
        </>
      ) : (
        cardImg
      )}
      <div className="student-hub-asset-card__body student-hub-asset-card-body">
        {supertitle && <p className="student-hub-asset-card-body__subtitle">{supertitle}</p>}
        <h3 className="student-hub-asset-card-body__title">{title}</h3>
        {subtitle && <p className="student-hub-asset-card-body__subtitle">{subtitle}</p>}
        {assetType === 'VIDEO' && (
          <p className="student-hub-asset-card-body__language">
            {language && supportedLanguagesMap[language]}
          </p>
        )}
        {showAssetIcon && (
          <span className="student-hub-asset-card__icon">
            <img
              src={assetType ? mapIconToAssetType[assetType] : iconBook}
              alt={assetType?.toLowerCase() || 'book'}
            />
          </span>
        )}
      </div>
    </div>
  );
};
