import React from 'react'
import './IconButton.scss'

interface IconButtonProps {
  ariaLabel: string
  onSelect?: () => void
  hideBorder?: boolean
  icon: string
}

/**
 * Creates a circular icon button.
 *
 * @param {string} ariaLabel - A label for the button
 * @param {() => void} onSelect? - This callback function is called when the button is clicked.
 * @param {boolean} hideBorder? - Removes the border around the icon button. By default, it is set to false.
 * @param {string} icon - The icon for button. Valid icons include:
 *        GridMenu
 *        Profile
 *        Hamburger
 *
 * @example
 * <IconButton ariaLabel="Navigation Menu" onSelect={someCallbackFunction} icon="grid-menu" hideBorder=false/>
 *
 */
function IconButton({ ariaLabel, onSelect, icon, hideBorder = false }: IconButtonProps) {
  const Icon: { [key: string]: string } = {
    GridMenu: 'grid-menu',
    Profile: 'profile',
    Hamburger: 'hamburger',
    Home: 'home'
  }
  const className = 'icon-button'
  return (
    <button
      data-testid={className}
      className={`${className}${hideBorder ? ' ' + className + '--hide-border' : ''}`}
      aria-label={ariaLabel}
      onClick={onSelect}
    >
      {icon in Icon ? (
        <img
          data-testid={`${className}__icon`}
          className={`${className}__icon ${className}__icon--${Icon[icon]}`}
          alt=""
        />
      ) : (
        ''
      )}
    </button>
  )
}

export default IconButton
