import { servicePath } from './basePath'

/**
 * Fetch video for L2M
 * @param videoId
 */
export const fetchL2mVideo = async (videoId: string): Promise<string> => {
  const response = await fetch(`${servicePath}limelight/signedUrl/media/${videoId}`)
  if (!response.ok) {
    throw new Error('An error occurred while trying to fetch the l2m video')
  }
  return response.text()
}
