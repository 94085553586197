import { fetchLtiParams } from './RetrieveLtiParamsApi'
import { acBasePath, l2mBasePath } from './basePath'

interface LtiLaunchRequestParams {
  appId: string
  epubId?: string
  customParams?: object
}

/**
 * Builds and submits an LTI Launch Request
 * @param LtiLaunchRequestParams
 * @param LtiLaunchRequestParams.appId The target application of the launch request
 * @param LtiLaunchRequestParams.epubId The uuid of the epub file
 * @param LtiLaunchRequestParams.customParams An object of custom parameters to be passed to the target application
 */
export const makeLtiLaunchRequest = async ({ appId, epubId = '', customParams }: LtiLaunchRequestParams) => {
  // fetch the LTI params
  const ltiParams: object = await fetchLtiParams(appId, customParams)

  const launchUrl = getLaunchUrl(appId, epubId)

  // create the form, set attributes and append it to the DOM
  const ltiLaunchRequestForm: HTMLFormElement = generateLtiLaunchForm(launchUrl)
  document.body.append(ltiLaunchRequestForm)

  // generate the hidden form inputs
  const hiddenFormInputs: DocumentFragment = generateHiddenFormInputs(ltiParams)

  // append the inputs to the form
  ltiLaunchRequestForm.append(hiddenFormInputs)

  //submit the form
  ltiLaunchRequestForm.submit()
}

/**
 * Generates an HTML form for the LTI launch request
 * @param launchUrl
 */
const generateLtiLaunchForm = (launchUrl: string): HTMLFormElement => {
  const ltiForm: HTMLFormElement = document.createElement('form')
  ltiForm.style.display = 'none'
  ltiForm.action = launchUrl
  ltiForm.method = 'POST'
  return ltiForm
}

/**
 * Generates a hidden form input for each param key/value
 * @param params
 */
const generateHiddenFormInputs = (params: object): DocumentFragment =>
  Object.entries(params).reduce((docFrag, [k, v]) => {
    const hiddenFormInput = document.createElement('input')
    hiddenFormInput.type = 'hidden'
    hiddenFormInput.name = k
    hiddenFormInput.value = v
    docFrag.append(hiddenFormInput)
    return docFrag
  }, new DocumentFragment())

function getLaunchUrl(appId: string, epubId: string) {
  let url: string
  if (appId === 'ActivityCenter') {
    url = `${acBasePath}api/v1/ac/lti/launch?epub_id=${epubId}`
  } else if (appId === 'L2M') {
    url = l2mBasePath
  } else {
    throw Error('An error occurred while retrieving the application lti launch url')
  }
  return url
}
