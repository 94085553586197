import React, { useContext, useEffect, useState } from 'react';
import { BookShelf } from 'component-library';
import { fetchTextAssignments } from '../../api/TextAssignmentApi';
import studentBanner from '../../assets/images/student_name_banner_carousel.svg';
import './MyPickSection.scss';
import Cookies from 'js-cookie';
import { StoreContext } from '../../store/store';
import { makeLtiLaunchRequest } from '../../api/LtiLaunchApi';
import { TextAssignment } from '../../types/textAssignmentSet';
import { emitDirectCall } from '../../utilities/update-dumbledata';

interface Book {
  id: string;
  title?: string;
  subTitle?: string;
  cover: string;
  alt?: string;
  onClick?: () => void;
}

export const MyPicksSection: React.FC = (): JSX.Element => {
  const [books, setBooks] = useState<Book[]>([]);
  const { state } = useContext(StoreContext);
  const userId = state.currentUserDetails?.user_id;
  const orgId = state.currentUserDetails?.orgId;
  const firstName = state.currentUserDetails?.firstName;
  const [fontClass, setFontClass] = useState('large');
  const className = 'student_banner_container';

  function loadBooks(textSetsResponse: TextAssignment[]) {
    const bookData = textSetsResponse.map((item) => ({
      title: item.title,
      cover: item.thumbnailUrl,
      alt: 'Book cover for ' + item.title,
      id: item.uuid,
      onClick: () => {
        const callbackUrl = `${process.env.REACT_APP_STUDENT_HUB_URL}/`;
        makeLtiLaunchRequest({
          appId: 'ActivityCenter',
          epubId: item.uuid,
          customParams: { callbackUrl }
        });
        emitDirectCall('studentText', { name: item.title, id: item.uuid, teacherRec: true });
      }
    }));
    setBooks(bookData);
  }

  function getFontSizeClass() {
    let fontSizeClass = 'large';
    if (firstName.length > 24) {
      fontSizeClass = 'x-small';
    } else if (firstName.length > 16) {
      fontSizeClass = 'small';
    } else if (firstName.length > 8) {
      fontSizeClass = 'medium';
    }
    return fontSizeClass;
  }

  useEffect(() => {
    const currentSchoolYear = Cookies.getJSON('R4R')?.currentSchoolYear || '';
    const role = Cookies.getJSON('R4R')?.roles || '';
    const loadTextSets = async () => {
      if (userId && orgId && currentSchoolYear && role === 'student') {
        try {
          const textAssignmentResponse = await fetchTextAssignments(userId, orgId, currentSchoolYear);
          loadBooks(textAssignmentResponse.results);
          setFontClass(getFontSizeClass);
        } catch (e) {
          console.error(e);
        }
      }
    };
    loadTextSets();
  }, [userId, orgId]);

  return (
    <>
      <div className={className}>
        {books && books.length > 0 && (
          <>
            <img className={`${className}_img`} src={studentBanner} alt="Student Banner" aria-hidden />
            <div className={`${className}_text`}>
              <h2 className={`${className}_text_span_${fontClass}`}>For {firstName}</h2>
            </div>
          </>
        )}
      </div>

      <BookShelf carouselLabel={`For ${firstName}`} books={books} />
    </>
  );
};
