import React, { useContext, useEffect, useState } from 'react';
import './Letter2Meaning.scss';
import { makeLtiLaunchRequest } from '../../api/LtiLaunchApi';
import { StoreContext } from '../../store/store';
import * as Dialog from '@radix-ui/react-dialog';
import uniqid from 'uniqid';
import { AblePlayer } from '../AblePlayer/AblePlayer';

import { fetchL2mVideo } from '../../api/L2mVideoApi';

import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { removeExistingAblePlayerScript } from '../../utilities/domManipulation';
import { addDatadogAction } from '../../utilities/updateDatadog';
import { emitDirectCall } from '../../utilities/update-dumbledata';

export const Letters2Meaning: React.FC = (): JSX.Element => {
  const { state } = useContext(StoreContext);

  const [shouldHideUi, setShouldHideUi] = useState<boolean>(false);
  const [l2mVideo, setL2mVideo] = useState<string | undefined>();
  const [open, setOpen] = useState<boolean>(false);

  const uniqVideoId = uniqid();

  const currentUserRole = state.currentUserDetails || null;

  const contentContainerId = 'student-hub-letters2Meaning__content';

  useEffect(() => {
    if (currentUserRole && currentUserRole.role === 'educator') {
      const loadL2mVideo = async () => {
        try {
          const videoDetails = await fetchL2mVideo('633ec64d143647858eced58f38550a32'); //static limeligh video Id
          setL2mVideo(videoDetails);
        } catch (e) {
          console.error(e);
        }
      };

      loadL2mVideo();
    }
  }, [currentUserRole]);

  const L2mButton = (): JSX.Element => {
    return (
      <button
        className="student-hub-letters2Meaning__button"
        onClick={() => {
          addDatadogAction('L2M Assessment Launch');
          emitDirectCall('myAssessment');
          makeLtiLaunchRequest({
            appId: 'L2M',
            customParams: { callbackUrl: process.env.REACT_APP_STUDENT_HUB_URL }
          });
        }}
        aria-haspopup="dialog"
        aria-label="launch Letters2Meaning quiz"
      >
        Letters2Meaning
      </button>
    );
  };

  const L2mVideoModal = (): JSX.Element => {
    return (
      <Dialog.Root
        open={open}
        onOpenChange={(open: boolean) => {
          setShouldHideUi(false);

          if (!open) {
            removeExistingAblePlayerScript(uniqVideoId);
          }
        }}
      >
        <Dialog.Trigger asChild>
          <button
            className="student-hub-letters2Meaning__button student-hub-letters2Meaning__button--open-video"
            aria-label="open Letters2Meaning assessment instructional video"
            onClick={() => setOpen(!open)}
            aria-controls={open ? contentContainerId : undefined}
          >
            Letters2Meaning
          </button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <Dialog.Overlay className="student-hub-letters2Meaning__overlay" />
          <Dialog.Content
            aria-label="Instructional video on Letters2Meaning assessment"
            aria-describedby={undefined}
            aria-labelledby={undefined}
            className={contentContainerId}
            id={contentContainerId}
          >
            {
              <AblePlayer
                playerId={'player__' + uniqVideoId}
                playerClass="student-hub-letters2Meaning__player"
                videoMp4Src={l2mVideo || ''}
                playerTheme="legacy"
                playerWidth="800"
                playerHeight="600"
                preLoad={true}
              />
            }

            <Dialog.Close asChild>
              <button
                className={classNames(
                  'student-hub-letters2Meaning__btn',
                  'student-hub-letters2Meaning__close-btn',
                  {
                    'student-hub-letters2Meaning__btn--hide-outline': shouldHideUi
                  }
                )}
                aria-label="close the video"
                onClick={() => setOpen(!open)}
              >
                <FontAwesomeIcon
                  data-testid="svg-close-icon"
                  className={classNames(
                    'student-hub-letters2Meaning__icon',
                    'student-hub-letters2Meaning__icon-close',
                    {
                      'student-hub-letters2Meaning__icon--hide': shouldHideUi
                    }
                  )}
                  icon={faTimesCircle}
                />
              </button>
            </Dialog.Close>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    );
  };

  return (
    <section className="student-hub-letters2Meaning">
      {currentUserRole && currentUserRole.role === 'educator' ? L2mVideoModal() : L2mButton()}
    </section>
  );
};
