import classNames from 'classnames';
import React from 'react';
import styles from './BookCard.module.scss';
import { Link } from 'react-router-dom';

export type BookCardProps = {
  className?: string;
  cover: string;
  subtitle: string;
  title: string;
  alt?: string;
  href?: string;
  onClick?: (e) => void;
  textId?: string;
};

export const BookCard = ({
  className,
  cover,
  subtitle,
  title,
  alt,
  href,
  onClick,
  textId
}: BookCardProps) => {
  return (
    <div className={classNames(styles.bookCard, className)}>
      <Link
        aria-label={`Activity Center: ${title}`}
        className={styles.bookCardLink}
        to={href}
        onClick={onClick}
      >
        <div className={styles.bookCardCoverImage}>
          <img
            src={cover}
            className={classNames(styles.bookCoverImage, className)}
            aria-labelledby={`book-card-title-${textId}`}
            alt={alt}
            aria-hidden
          />
          <p id={`book-card-title-${textId}`} className={styles.bookCardTitle}>
            {title}
          </p>
          <p className={styles.bookCardSubTitle}>{subtitle}</p>
        </div>
      </Link>
    </div>
  );
};
