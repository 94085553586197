import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AuthProvider } from 'authentication-library';
import { datadogRum } from '@datadog/browser-rum';
import { getEnvironment } from './utilities/getEnvironment';
import { setDatadogUser } from './utilities/updateDatadog';

const rootElement = document.getElementById('root') as HTMLElement;
const adobeDTMScript = document.createElement('script');

if (getEnvironment()) {
  adobeDTMScript.src =
    'https://assets.adobedtm.com/119ebaf99f7d/ba00649e0378/launch-e57c90234c70-development.min.js';
} else {
  adobeDTMScript.src = 'https://assets.adobedtm.com/119ebaf99f7d/ba00649e0378/launch-0dd3a35c41b8.min.js';
}
adobeDTMScript.addEventListener('load', () => {
  const pageBottomScript = document.createElement('script');
  pageBottomScript.innerHTML = '_satellite.pageBottom();';
  rootElement?.parentElement?.appendChild(pageBottomScript);
});
document.head.appendChild(adobeDTMScript);

datadogRum.init({
  applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID || '',
  clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN || '',
  site: 'datadoghq.com',
  service: process.env.REACT_APP_DATADOG_RUM_SERVICE,
  env: process.env.REACT_APP_DATADOG_RUM_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  trackUserInteractions: true,
  trackResources: false,
  trackLongTasks: false,
  defaultPrivacyLevel: 'mask'
});
setDatadogUser();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider app="STUDENT_HUB">
        <App />
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);
