import React from 'react'
import './GlobalFooter.scss'

/**
 * Component holding footer information and links
 *
 * @returns footer component with logo and links
 */

const GlobalFooter = () => {
  const className = 'global-footer'
  return (
    <footer className={`${className}`}>
      <div className={`${className}__scholastic-icon`} data-testid={`${className}__scholastic-icon`}></div>
      <a
        className={`${className}__nav-link privacy`}
        data-testid={`${className}__nav-link-privacy`}
        href="https://www.scholastic.com/edtechprivacy.htm"
      >
        PRIVACY POLICY
      </a>
      <a
        className={`${className}__nav-link`}
        data-testid={`${className}__nav-link-terms`}
        href="https://www.scholastic.com/terms.htm"
      >
        Terms of Use
      </a>
      <a
        className={`${className}__nav-link`}
        data-testid={`${className}__nav-link-about`}
        href="https://www.scholastic.com/aboutscholastic"
      >
        About Scholastic
      </a>
      <p className={`${className}__copy-text`} data-testid={`${className}__copy-text`}>
        TM ® & © {new Date().getFullYear()} Scholastic Inc. All Rights Reserved.
      </p>
    </footer>
  )
}

export default GlobalFooter
