import React from 'react';
import './PageHeader.scss';
import iconCaretLeft from '../../assets/images/icon_caret_left.svg';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

interface PageHeaderProps {
  logoImgUrl: string;
  moduleTitle: string;
  // if provided use for back button routing, otherwise return home
  backBtnUrl?: string;
  shouldHideBackBtn?: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  logoImgUrl,
  moduleTitle,
  backBtnUrl = '/',
  shouldHideBackBtn = false
}): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="student-hub-page-header">
      <div className="student-hub-page-header__btn-container">
        <button
          className={classNames('student-hub-page-header__btn', {
            'student-hub-page-header__btn--hidden': shouldHideBackBtn
          })}
          aria-labelledby="student-hub-page-header__btn-label"
          onClick={() => navigate(backBtnUrl)}
        >
          <img src={iconCaretLeft} alt="" />
        </button>
        {!shouldHideBackBtn && (
          <p id="student-hub-page-header__btn-label" className="student-hub-page-header__btn-label">
            back
          </p>
        )}
      </div>
      <div className="student-hub-page-header__h1">
        <img className="student-hub-page__logo" width="181" height="36" src={logoImgUrl} alt={moduleTitle} />
      </div>
    </div>
  );
};
