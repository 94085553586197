import * as ScrollArea from '@radix-ui/react-scroll-area';
import React, { PropsWithChildren, SyntheticEvent, forwardRef } from 'react';
import classNames from 'classnames';
import styles from './HorizontalScrollArea.module.scss';

const scrollbarHeight = '4px';

export interface HorizontalScrollAreaProps extends PropsWithChildren {
  contentScrollbarSpacing?: string;
  scrollbarTrackWidth?: string;
  scrollAreaViewportClassName?: string;
  className?: string;
  ariaLabel?: string;
  onScroll?: (e?: SyntheticEvent) => void;
}

export const HorizontalScrollArea = forwardRef<HTMLDivElement, HorizontalScrollAreaProps>(
  (
    {
      contentScrollbarSpacing,
      scrollbarTrackWidth = '96px',
      ariaLabel,
      className,
      scrollAreaViewportClassName,
      onScroll,
      children
    },
    forwardedRef
  ) => (
    <ScrollArea.Root className={classNames(styles.scrollAreaRoot, className)} type="auto">
      <ScrollArea.Viewport
        role="group"
        aria-label={ariaLabel}
        className={scrollAreaViewportClassName}
        style={{ marginBottom: `calc(${scrollbarHeight} + ${contentScrollbarSpacing})` }}
        ref={forwardedRef}
        onScroll={(e) => onScroll?.(e)}
      >
        {children}
      </ScrollArea.Viewport>
      <ScrollArea.Scrollbar
        style={{
          width: scrollbarTrackWidth
        }}
        className={classNames(styles.scrollArea)}
        orientation="horizontal"
      >
        <ScrollArea.Thumb className={classNames(styles.scrollAreaThumb)} />
      </ScrollArea.Scrollbar>
    </ScrollArea.Root>
  )
);
