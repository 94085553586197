import React, { useContext, useEffect, useState } from 'react';
import { ActionType, StoreContext } from '../../store/store';
import { fetchAllContentModules } from '../../api/ContentModuleApi';
import './ModuleSelection.scss';
import { ContentModule } from '../../types/contentModule';
import { moduleMetadataMap } from '../../utilities/moduleMetadataMap';
import { ModuleCard } from '../ModuleCard/ModuleCard';
import { useAuth } from 'authentication-library';

interface MascotImgUrls {
  [key: string]: string;
}

export const ModuleSelection: React.FC = (): JSX.Element => {
  const { dispatch } = useContext(StoreContext);
  const [entitledModules, setEntitledModules] = useState<ContentModule[]>([]);
  const [mascotImgUrls, setMascotImgUrls] = useState<MascotImgUrls>();
  const { roles, modules, entitlements } = useAuth();

  useEffect(() => {
    const header = document.querySelector('.global-header');
    header?.classList.add('global-header--transparent');
    return () => {
      header?.classList.remove('global-header--transparent');
    };
  }, []);

  function filterModuleByRole(module: ContentModule) {
    if (entitlements.includes('R4R_System') || roles === 'student') {
      return module.id !== 'r4r_module_004' && (modules as string[]).includes(module.id);
    } else {
      return module.id === 'r4r_module_001' && (modules as string[]).includes(module.id);
    }
  }

  useEffect(() => {
    fetchAllContentModules()
      .then((moduleData) => {
        const filteredModules = moduleData.filter((module) => filterModuleByRole(module));
        setEntitledModules(filteredModules);
      })
      .catch((e) => console.error(e));
  }, [modules]);

  useEffect(() => {
    const getMascotUrl = () => {
      if (!entitledModules.length) {
        return;
      }
      const mascotUrls = entitledModules.reduce((acc, module) => {
        const { id } = module;
        const currentModule = moduleMetadataMap[module.id];
        acc = { ...acc, [id]: currentModule.mascotImg.default };
        return acc;
      }, {});

      setMascotImgUrls(mascotUrls);
    };
    getMascotUrl();
    window.addEventListener('resize', getMascotUrl);

    return () => {
      window.removeEventListener('resize', getMascotUrl);
    };
  }, [entitledModules]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_CURRENT_MODULE_ID, data: undefined });
  }, [dispatch]);

  return (
    <section className="student-hub-modules">
      {entitledModules.map(({ title, id }, idx) => (
        <React.Fragment key={`moduleCard-${idx}`}>
          <ModuleCard
            id={id}
            title={title}
            mascotImgUrl={mascotImgUrls?.[id]}
            logoImgUrl={moduleMetadataMap[id].logoImgUrl}
            cssNameSpace={moduleMetadataMap[id].cssNameSpace}
          />
        </React.Fragment>
      ))}
    </section>
  );
};
