import React, { useEffect } from 'react';

import uniqid from 'uniqid';
import classNames from 'classnames';

import './AblePlayer.scss';

export interface AbleVideoPlayerProps {
  /**
   * This is player uniq Id
   * needs to be passad a unique reference to each instance
   * @type string
   */

  playerId: string;

  /**
   * This is Webm video source url
   * @type string
   */
  videoWebmSrc?: string;

  /**
   * This is mp4 video source url
   * @type string
   */
  videoMp4Src: string;

  /**
   * Theme to choose i.e 'legacy' | '2020'
   * @default  '2020'
   */

  playerTheme: 'legacy' | '2020';

  /**
   * This is url for caption  source file(should be in .vtt format)
   * @type string
   */

  captionSrcUrl?: string;

  /**
   * This is video description source url(.vtt format)
   * @type string
   */

  descriptionSrcUrl?: string;

  /**
   * Player width
   * @type string | number
   */

  playerWidth?: string | number;

  /**
   * Player height
   * @type string | number
   */

  playerHeight?: string | number;

  /**
   * Whether to preload media or not
   * @type boolean
   */

  preLoad?: boolean;

  /**
   * Player speed icon to show between “animals” (default) or “arrows”.
   * @default 'animals'
   */
  speedIcon?: 'animals' | 'arrows';

  /**
   * Additional Classname for custom style
   */
  playerClass?: string;
}

export const AblePlayer = ({
  playerId = uniqid(),
  videoWebmSrc,
  videoMp4Src,
  playerTheme = '2020',
  captionSrcUrl,
  descriptionSrcUrl,
  preLoad,
  speedIcon = 'arrows',
  playerClass
}: AbleVideoPlayerProps) => {
  document.cookie =
    'Able-Player=' +
    JSON.stringify({
      preferences: {
        prefCaptions: 0
      }
    });

  useEffect(() => {
    const loadScript = function (src: string, id: string) {
      if (document.getElementById(id)) {
        return;
      }
      const tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      tag.id = id;
      tag.dataset.testid = 'ableplayer';
      document.body.appendChild(tag);
    };

    const loadStyle = function (src: string, id: string) {
      if (document.getElementById(id)) {
        return;
      }
      const tag = document.createElement('link');
      tag.rel = 'stylesheet';
      tag.href = src;
      tag.id = id;
      document.body.appendChild(tag);
    };

    loadScript('//ajax.googleapis.com/ajax/libs/jquery/3.5.0/jquery.min.js', 'jquery');
    loadScript('//cdn.jsdelivr.net/npm/js-cookie@3.0.1/dist/js.cookie.min.js', 'js-cookie');
    loadScript(`${process.env.PUBLIC_URL}/ableplayer/build/ableplayer.js`, `${playerId}-ableplayer-js`);
    loadStyle(`${process.env.PUBLIC_URL}/ableplayer/build/ableplayer.min.css`, 'ableplayer-css');
  }, [playerId]);

  return (
    <div className="ableplayer-wrapper">
      <video
        id={playerId}
        className={classNames('ableplayer-wrapper__item', playerClass)}
        data-able-player
        preload={preLoad ? 'auto' : 'metadata'}
        data-skin={playerTheme}
        data-speed-icons={speedIcon}
        data-include-transcript={false}
        playsInline
      >
        {videoWebmSrc && <source type="video/webm" src={videoWebmSrc} />}
        {videoMp4Src && <source type="video/mp4" src={videoMp4Src} />}
        {captionSrcUrl && <track kind="captions" src={captionSrcUrl} />}
        {descriptionSrcUrl && <track kind="descriptions" src={descriptionSrcUrl} />}
      </video>
    </div>
  );
};
