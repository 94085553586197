import { servicePath } from './basePath';
import { TextAssignmentResult } from '../types/textAssignmentSet';

/**
 * Fetch Text Assignments
 * @param studentId
 * @param orgId
 * @param schoolYear
 */
export const fetchTextAssignments = async (
  studentId: string,
  orgId: string,
  schoolYear: string
): Promise<TextAssignmentResult> => {
  const response = await fetch(
    `${servicePath}r4r/assignments/student/${studentId}/organization/${orgId}?schoolYear=${schoolYear}`
  );
  if (!response.ok) {
    throw new Error('An error occurred while trying to fetch the text assignments');
  }
  return response.json();
};
