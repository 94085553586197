import React, { useContext, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './TeacherBanner.scss';
import { Link } from 'react-router-dom';
import { getEnvironment } from '../../utilities/getEnvironment';
import { ActionType, StoreContext } from '../../store/store';
import * as Cookies from 'js-cookie';
import { emitDirectCall } from '../../utilities/update-dumbledata';

export const TeacherBanner = () => {
  const { state, dispatch } = useContext(StoreContext);

  useEffect(() => {
    const storeUserContextCookies = () => {
      const userDetails = Cookies.getJSON('sdm_nav_ctx');
      if (userDetails) {
        dispatch({ type: ActionType.SET_CURRENT_USER, data: userDetails });
      }
    };
    storeUserContextCookies();
  }, [dispatch]);

  const generateTeacherHubLink = (): string => {
    return !getEnvironment()
      ? 'https://teacher-hub.ready4reading.scholastic.com/'
      : `https://teacher-hub.${getEnvironment()}.ready4reading.scholastic.com/`;
  };

  return (
    <>
      {state.currentUserDetails && state.currentUserDetails.role === 'educator' && (
        <div className="student-hub-teacher-banner">
          <span className="student-hub-teacher-banner__icon">
            <FontAwesomeIcon icon={faCircleInfo} />
          </span>
          <span className="student-hub-teacher-banner__text">
            You are viewing the Student Hub. &nbsp;{' '}
            <Link
              to={generateTeacherHubLink()}
              className="student-hub-teacher-banner__link"
              aria-label="Go to teacher Hub"
              onClick={() => emitDirectCall('returnToTeacherHub')}
            >
              Return to Teacher Hub
            </Link>
          </span>
        </div>
      )}
    </>
  );
};
