import { TopicSet } from '../types/topicSet'
import { basePath } from './basePath'

/**
 * Fetch all the topic sets (cards sets and text sets) for the given modules
 * @param moduleId
 */
export const fetchTopicSets = async (moduleId: string): Promise<TopicSet[]> => {
  const response = await fetch(`${basePath}textsets?moduleIds=${moduleId}`)
  if (!response.ok) {
    throw new Error('An error occurred while trying to fetch the text sets data')
  }
  return response.json()
}
