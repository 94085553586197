import React, { useContext } from 'react';
import './BackgroundImage.scss';
import { moduleMetadataMap } from '../../utilities/moduleMetadataMap';
import { StoreContext } from '../../store/store';
import defaultBackgroundImg from '../../assets/images/background_stripes.svg';

export const BackgroundImage: React.FC = (): JSX.Element => {
  const { state } = useContext(StoreContext);

  const backgroundImg =
    (state.currentModuleId && moduleMetadataMap[state.currentModuleId].backgroundImgUrl) ||
    defaultBackgroundImg;

  return (
    <img
      src={backgroundImg}
      alt=""
      className="student-hub-background"
      width="486"
      height="231"
      data-testid={`background-${state.currentModuleId ?? 'default'}`}
    />
  );
};
