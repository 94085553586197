import axios from 'axios'
import { getServiceUrl } from '../../utilities'

const SERVICE_URL = getServiceUrl() + '/aws'

function getSignedCookies() {
  return axios
    .get<boolean>(`${SERVICE_URL}/signedCookies`, { withCredentials: true })
    .then((response) => response.data)
}

export { getSignedCookies }
