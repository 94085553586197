import React, { useEffect, useRef } from 'react'
import './LazyLoadImage.scss'

interface LazyLoadImageProps {
  src: string
  alt: string
  width: string
  height: string
  onError?: (e) => void
}

/**
 * A lazy loading img wrapper
 *
 * @param src image src URI
 * @param alt image alt text
 * @param width the intrinsic width attribute
 * @param height the intrinsic height attribute
 * @param onError optional error handling
 * @constructor
 *
 * image is minimally styled, to override add the .student-hub-lazy-load__img class
 * to your CSS stylesheet
 *
 */
export const LazyLoadImage: React.FC<LazyLoadImageProps> = ({
  src,
  alt,
  width,
  height,
  onError
}): JSX.Element => {
  const imgRef = useRef<HTMLImageElement>(new Image())

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = ([entry], observer) => {
      if (entry.isIntersecting) {
        const image = entry.target as HTMLImageElement
        image.src = image.dataset.src as string
        observer.unobserve(entry.target)
      }
    }
    const observer = new IntersectionObserver(observerCallback)
    observer.observe(imgRef.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <img
      width={width}
      height={height}
      src=""
      data-src={src}
      alt={alt}
      className="student-hub-lazy-load__img"
      ref={imgRef}
      onError={onError}
    />
  )
}
