import axios from 'axios'
import { ModuleId } from '../../types'
import { stringifyArrayParams, getServiceUrl } from '../../utilities'

const SERVICE_URL = getServiceUrl() + '/r4r'

export type SystemResourceCategory = {
  uuid: string
  displayName: string
  level: number
  language: string
  sizeLarge?: boolean
  description?: string
  children?: SystemResourceAsset[] | SystemResourceCategory[]
}

export type SystemResourceAsset = {
  uuid: string
  displayName: string
  level: number
  language: string
  type: string
  thumbnailUrl: string
  url: string
  metadata: string
  sizeLarge: boolean
}

function getSystemResourceAsset(moduleUuids: ModuleId[], assetUuid: string) {
  const params = stringifyArrayParams({ moduleUuids })
  return axios
    .get<SystemResourceCategory[]>(`${SERVICE_URL}/systemResource/${assetUuid}?${params}`)
    .then((response) => response.data)
}

function getSystemResourceAtLevel(moduleUuids: ModuleId[], level: string) {
  const params = stringifyArrayParams({ moduleUuids })
  return axios
    .get<SystemResourceCategory[]>(`${SERVICE_URL}/systemResource/?${params}&level=${level}`)
    .then((response) => response.data)
}

export { getSystemResourceAsset, getSystemResourceAtLevel }
