import { getEnvironment } from './getEnvironment';
import cookies from 'js-cookie';

declare global {
  interface Window {
    dumbleData?: {
      version?: string;
      user?: Record<string, unknown>;
      siteInfo?: Record<string, unknown>;
      page?: Record<string, unknown>;
    };
    _satellite?: {
      track: (label: string, props?: object) => void;
    };
  }
}

export function updateDumbledataVersion() {
  if (!window.dumbleData) {
    window.dumbleData = {};
  }
  window.dumbleData.version = '1.0';
}

export function updateDumbledataUser() {
  if (!window.dumbleData) {
    window.dumbleData = { user: {} };
  }
  const sdmCookie = cookies.get('R4R');
  const userData = JSON.parse(sdmCookie || '{}');
  const { user_id, sdmNavCtx, scholasticGradeCode, parentOrgId } = userData;
  const sdmNavCtxDecode = sdmNavCtx?.replaceAll('+', ' ');
  const { orgId, role } = JSON.parse(sdmNavCtxDecode || '{}');
  window.dumbleData.user = {
    userRole: role === 'educator' || role === 'teacher' ? 'teacher' : 'student',
    sdmId: user_id,
    parentOrgId: parentOrgId,
    orgId: orgId,
    grade: scholasticGradeCode
  };
}

export function updateDumbledataSiteInfo() {
  if (!window.dumbleData) {
    window.dumbleData = { siteInfo: {} };
  }
  window.dumbleData.siteInfo = {
    name: 'Ready4Reading',
    environment: getEnvironment(),
    audience: 'student'
  };
}

interface PageProps {
  pageName: string;
  siteSection: string;
  previousPageURL?: string;
}

export function updateDumbledataPage({ pageName, siteSection, previousPageURL }: PageProps) {
  if (!window.dumbleData) {
    window.dumbleData = { page: {} };
    updateStaticDumbleData();
  }
  if (
    !window.dumbleData.page?.destinationURL ||
    window.dumbleData.page.destinationURL !== window.location.href
  ) {
    window.dumbleData.page = {
      domain: window.location.hostname,
      destinationURL: window.location.href,
      isError: false,
      pageName,
      pageInfo: {
        breadcrumb: window.location.pathname
      },
      siteSection,
      previousPageURL
    };
    if (window._satellite) {
      emitDirectCall('page-view');
    }
  }
}

export const emitDirectCall = (event: string, details?: object) => {
  if (typeof window !== 'undefined' && window._satellite) {
    details ? window._satellite.track(event, details) : window._satellite.track(event);
  }
};

interface DumbleDataProps {
  page: PageProps;
}

export function updateStaticDumbleData() {
  if (!window.dumbleData) {
    window.dumbleData = window.dumbleData || {};
  }
  updateDumbledataVersion();
  updateDumbledataUser();
  updateDumbledataSiteInfo();
}

export function updateDumbleData({ page }: DumbleDataProps) {
  if (typeof window !== 'undefined') {
    updateStaticDumbleData();
    updateDumbledataPage(page);
  }
}

export default updateDumbleData;
