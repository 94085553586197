import React from 'react';
import HorizontalOverflowList from './HorizontalOverflowList';
import { BookCard } from './BookCard/BookCard';

interface Book {
  id: string;
  title?: string;
  subTitle?: string;
  cover: string;
  alt?: string;
  onClick?: () => void;
}

interface BookshelfProps {
  carouselLabel: string;
  books: Book[];
}

const BookShelf = ({ carouselLabel, books }: BookshelfProps) => {
  return (
    <HorizontalOverflowList.Root ariaLabel={carouselLabel}>
      {books.map((book, bookIndex) => (
        <HorizontalOverflowList.Item key={`${carouselLabel}-${book.id}-${bookIndex}`}>
          <BookCard
            cover={book.cover}
            title={book.title}
            subtitle={book.subTitle}
            alt={book.alt}
            textId={book.id}
            onClick={book.onClick}
            href="#!"
          />
        </HorizontalOverflowList.Item>
      ))}
    </HorizontalOverflowList.Root>
  );
};

export default BookShelf;
